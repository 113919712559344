$padding-big: 40px;
$padding-medium: 20px;
$margin-big: 40px;
$margin-medium: 20px;
$border-radius-big: 40px;
$border-radius-medium: 10px;
$box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
$field-border-width: 1px;
$space: 8;

@function theme-spacing($step) {
  @return #{$step * $space}px;
}

@mixin Medium() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin Large() {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin XLarge() {
  @media (min-width: 1920px) {
    @content;
  }
}

:root {
  --padding-medium: 10px;
  --padding-big: 15px;
  --margin-big: 15px;
  --margin-medium: 10px;
  --border-radius-big: 25px;
  --border-radius-medium: 10px;
  --field-border-width: 1px;

  @include Large() {
    --padding-medium: 20px;
    --padding-big: 40px;
    --margin-big: 40px;
    --margin-medium: 20px;
    --border-radius-big: 40px;
    --border-radius-medium: 10px;
  }
}
