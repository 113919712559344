@use "@angular/material" as mat;
@use "./styles/themes/light";
@use "./styles/components";
@use "./styles/common";
@use "./styles/typography/config" as typography;
@use "./styles/common/params" as params;

@include mat.core();

@include mat.core-theme(light.$my-app-light-theme);
@include mat.all-component-themes(light.$my-app-light-theme);


@include components.theme(
    light.$my-app-light-theme,
    typography.$my-app-typography
);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Popins, "Helvetica Neue", sans-serif;
}

mat-spinner {
  width: params.theme-spacing(3) !important;
  height: params.theme-spacing(3) !important;
}

mat-toolbar {
  img {
    height: 35px;
  }

  padding: 0 40px;
  box-shadow: 0px 4px 10px rgba(48, 52, 54, 0.05);
  position: sticky;
  top: 0;
  z-index: 100;
  justify-content: center;

  @include params.Large() {
    justify-content: start;
  }
}

.loading, .error {
  display: flex;
  width: 100%;
  height: calc(100vh - 30px - var(--padding-big) - var(--padding-big));

  mat-spinner {
    height: 100%;
    width: 100%;
    margin: auto;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.60);
}


.mat-bottom-sheet-container {
  padding: 0 !important;
  background: none;
  box-shadow: none;
  max-height: 90vh !important;
}

ul {
  padding-left: 0;
  margin: 0;
  //list-style-type: none; /* Убираем маркеры списка */
}

ul:not(:last-child) {
  margin-bottom: params.theme-spacing(2);
}

li {
  margin-left: params.theme-spacing(2); /* Отступ перед каждым элементом списка */
  position: relative;

  &:not(last-of-type) {
    padding-bottom: params.theme-spacing(1);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-horizontal-stepper-wrapper, .mat-horizontal-content-container {
  height: 100%;
  width: calc(100% - 1rem);
}

.mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.mat-horizontal-stepper-header {
  padding: 0 1rem !important;
}

.mat-horizontal-content-container {
  padding: 0 1rem 1rem 1rem !important;
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0,0,0,0);
}
