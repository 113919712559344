@use "params" as params;

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  min-height: 46px;
  input {
    line-height: 46px;
  }


  @include params.Large() {
    min-height: 52px;
    input {
      line-height: 52px;
    }
  }
}

.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 0 !important;

  .mdc-notched-outline__leading {
    border-radius: var(--border-radius-medium) 0 0 var(--border-radius-medium) !important;
    //width: 24px !important;
    border-left-width: var(--field-border-width) !important;
    border-bottom-width: var(--field-border-width) !important;
    border-top-width: var(--field-border-width) !important;
  }

  .mdc-notched-outline__trailing {
    border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0 !important;
    border-right-width: var(--field-border-width) !important;
    border-bottom-width: var(--field-border-width) !important;
    border-top-width: var(--field-border-width) !important;
  }
}

.mdc-text-field--outlined {
  //padding-left: 24px !important;
  //padding-right: 24px !important;
}

.mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
  z-index: 1;

  mat-icon {
    padding-left: 0 !important;
    //width: 16px;
    //height: 16px;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  //--mat-mdc-form-field-label-transform: translateY(-12px) scale(1) !important;
}

.mat-form-field-appearance-outline.mat-mdc-form-field-label-always-float {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    width: auto !important;
    //padding-top: 36px !important;
    //padding-bottom: 10px !important;
  }
  .mdc-text-field--outlined .mdc-floating-label {
    //left: 0 !important;
  }
  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    //border-top-width: 2px !important;
    //border-bottom-width: 2px !important;
    //border-top-style: solid !important;
  }
}

.mat-mdc-checkbox .mdc-form-field {
  font-weight: 500 !important;
}

.stepper-form-container {
  --border-width: 1px;

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    min-height: 56px !important;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix input {
    line-height: 24px !important;
  }

  .mdc-text-field--outlined {

    .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
      //border-top: 2px solid !important;
      //border-bottom: 2px solid !important;
    }

    .mdc-notched-outline__leading {
      border-left-width: var(--border-width) !important;
      border-bottom-width: var(--border-width) !important;
      border-top-width: var(--border-width) !important;
    }

    .mdc-notched-outline__trailing {
      border-right-width: var(--border-width) !important;
      border-bottom-width:var(--border-width) !important;
      border-top-width: var(--border-width) !important;
    }

    .mdc-notched-outline__notch {
      border-bottom-width: var(--border-width) !important;
    }

  }
}

