@use 'sass:map';
@use "@angular/material" as mat;

@mixin color($theme){
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $background: map.get($color-config, 'background');

  a {
    color: mat.get-color-from-palette($accent-palette, 'default') !important;
    text-decoration: none !important;
    &:hover {
      color: mat.get-color-from-palette($accent-palette, 'darker') !important;
    }
  }
}

@mixin typography($typography-config) {
  mat-checkbox.outline {
    @include mat.typography-level($typography-config, "subtitle-1");
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  @if $typography-config != null {
    @include typography($typography-config);
  }
}
