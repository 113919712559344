@use "sass:map";
@use "@angular/material" as mat;
@use "../typography/config" as typography;

$my-app-light-primary: mat.define-palette(mat.$grey-palette, A200, A100, A400);
$my-app-light-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
$my-app-light-warn: mat.define-palette(mat.$red-palette, A200, A100, A400);

$my-typography: mat.define-typography-config();

$my-app-light-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-app-light-primary,
        accent: $my-app-light-accent,
        warn: $my-app-light-warn,
        grey: $my-app-light-accent,
      ),
      typography: typography.$my-app-typography,
    )
);

@include mat.typography-hierarchy(typography.$my-app-typography);

@media screen and (max-width: 1280px) {
  @include mat.typography-hierarchy(typography.$my-app-typography-mobile);
}
