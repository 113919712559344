.mdc-tab__content {
  //height: 55px !important;
}

.mat-mdc-tab:not(.mdc-tab--stacked) {
  height: 55px !important;
}

.mat-mdc-tab {
  font-weight: 500 !important;
}

mat-tab-group {
  overflow-y: auto;
  height: 100%;
}

