@use 'sass:map';
@use "@angular/material" as mat;

@mixin color($theme){
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $background: map.get($color-config, 'background');

  .bg-card {
    background: mat.get-color-from-palette($background, 'card') !important;
  }

  .color-primary {
    color:  mat.get-color-from-palette($primary-palette, 'default');
  }

  .bg-color-primary {
    background-color:  mat.get-color-from-palette($primary-palette, 'default') !important;
  }

  .color-primary-lighter {
    color:  mat.get-color-from-palette($primary-palette, 'lighter');
  }

  .bg-color-primary-lighter {
    background-color:  mat.get-color-from-palette($primary-palette, 'lighter') !important;
  }

  .color-primary-darker {
    color:  mat.get-color-from-palette($primary-palette, 'darker');
  }

  .bg-color-accent {
    background-color:  mat.get-color-from-palette($accent-palette, 'default');
  }

  .color-accent {
    color:  mat.get-color-from-palette($accent-palette, 'default');
  }

  .bg-color-accent-lighter {
    background-color:  mat.get-color-from-palette($accent-palette, 'lighter') !important;
  }

  .bg-color-accent {
    background-color:  mat.get-color-from-palette($accent-palette, 'default') !important;
  }

  .bg-color-accent-darker {
    background-color:  mat.get-color-from-palette($accent-palette, 'darker') !important;
    color: var(--mdc-protected-button-label-text-color, inherit);
  }

  .color-accent-lighter {
    color:  mat.get-color-from-palette($accent-palette, 'lighter');
  }

  .color-accent-darker {
    color:  mat.get-color-from-palette($accent-palette, 'darker');
  }

  .bg-color-card {
    background-color: mat.get-color-from-palette($background, 'card');
  }
}

@mixin typography($typography-config) {

}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  @if $typography-config != null {
    @include typography($typography-config);
  }
}
